// import wx from 'wecomjsdk';
const configJsApiList = ['getCurExternalContact', 'sendChatMessage', 'hideChatAttachmentMenu'];
const agentConfigJsApiList = ['getCurExternalContact', 'sendChatMessage', 'hideChatAttachmentMenu'];

export function initializeWxSdk(config) {
  return new Promise((resolve, reject) => {
    const {
      appId,
      jsapi_nonceStr: wxNonceStr,
      jsapi_timestamp: wxTimeStamp,
      jsapi_signature: wxSignature,

      agent_jsapi_nonceStr: agentNonceStr,
      agent_jsapi_timestamp: agentTimeStamp,
      agent_jsapi_signature: agentSignature
    } = config;

    const corpId = appId;
    const agentId = sessionStorage.getItem('sm:ready-agentId');

    wx.config({
      beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId, // 必填，企业微信的corpID，必须是本企业的corpID，不允许跨企业使用
      timestamp: wxTimeStamp, // 必填，生成签名的时间戳
      nonceStr: wxNonceStr, // 必填，生成签名的随机串
      signature: wxSignature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
      jsApiList: configJsApiList // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
    });

    wx.ready(function() {
      wx.agentConfig({
        corpid: corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: agentId, // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: agentTimeStamp, // 必填，生成签名的时间戳
        nonceStr: agentNonceStr, // 必填，生成签名的随机串
        signature: agentSignature,// 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: agentConfigJsApiList, //必填，传入需要使用的接口名称
        success: function(res) {
          wx.invoke('hideChatAttachmentMenu', {
            menuList: ["sendMessage"] // 要隐藏的菜单项,sendMessage。即附件栏底部发送按钮。
          });
          resolve(res);
        },
        fail: function(res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            reject('版本过低请升级');
          }
        }
      });
    });

    wx.error(function (res) {
      console.log('wx error -->', res);
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    });
  });
}

export function initAgentConfig(config) {
  return new Promise((resolve) => {
    const {
      appId,
      agent_jsapi_nonceStr: agentNonceStr,
      agent_jsapi_timestamp: agentTimeStamp,
      agent_jsapi_signature: agentSignature
    } = config;

    const corpId = appId;
    const agentId = sessionStorage.getItem('sm:ready-agentId');
    wx.ready(function() {
      wx.agentConfig({
        corpid: corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
        agentid: agentId, // 必填，企业微信的应用id （e.g. 1000247）
        timestamp: agentTimeStamp, // 必填，生成签名的时间戳
        nonceStr: agentNonceStr, // 必填，生成签名的随机串
        signature: agentSignature,// 必填，签名，见附录-JS-SDK使用权限签名算法
        jsApiList: agentConfigJsApiList, //必填，传入需要使用的接口名称
        success: function(res) {
          wx.invoke('hideChatAttachmentMenu', {
            menuList: ["sendMessage"] // 要隐藏的菜单项,sendMessage。即附件栏底部发送按钮。
          });
          resolve(res);
        },
        fail: function(res) {
          resolve(res);
        }
      });
    });
  });
}
