import { getAuthLink, getWxConfig, getWxUserInfo } from '@/api/wx-config';
import { initializeWxSdk } from '@/utils/wx-init';

function watchUrl() {
  const searchParams = new URLSearchParams(window.location.search);
  let [appId, agentId, code] = [searchParams.get('appId'), searchParams.get('agentId'), searchParams.get('code')];

  if (appId && agentId) { // 企业员工 初次进入
    const { origin, hash } = window.location;
    const redirectUrl = `${origin}/${hash}?appId=${appId}`;

    sessionStorage.setItem('sm:ready-appId', appId);
    sessionStorage.setItem('sm:ready-agentId', agentId);

    const params = {
      appId,
      redirectUrl
    };
    getAuthLink(params).then(res => {
      if (res instanceof Object) {
        const { oauthUrl } = res;
        window.location.href = oauthUrl; // 重定向到oauth授权页面
      }
    });
  }

  if (code) { // 已授权或授权成功 获取到授权code
    getWxUserInfo({ code }).then(res => {
      if (res instanceof Object) {
        sessionStorage.setItem('sm:staff-info', JSON.stringify(res));
      }
    });

    // 初始化企业微信JSSDK
    const wxConfigParams = {
      url: window.location.href.split('#')[0]
    };
    getWxConfig(wxConfigParams).then(config => {
      initializeWxSdk(config);
    });
  }
}

(function() {
  watchUrl();
})();

