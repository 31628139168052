import request from '@/utils/request';

export function getWxConfig({ url } = {}) {
  return request({
    url: 'wx/cp/js/getJsConf/getJsConf',
    method: 'post',
    data: {
      jsApiUri: url,
      agentJsApiUri: url
    }
  });
}

/**
 * @description 获取网页授权链接
 * @param appId
 * @param agentId
 * @param redirectUrl
 * @returns {*}
 */
export function getAuthLink({ appId, agentId, redirectUrl: redirectUri } = {}) {
  return request({
    url: 'wx/tp/user/oauth/getOauthUrl',
    method: 'get',
    params: {
      appId,
      agentId,
      redirectUri
    }
  });
}

/**
 * @description 获取用户信息
 * @param code
 * @returns {*}
 */
export function getWxUserInfo({ code } = {}) {
  return request({
    url: '/wx/tp/user/oauth/getOauthInfo',
    method: 'get',
    params: { code }
  });
}
