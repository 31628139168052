import router from './router';
import { getWxConfig } from '@/api/wx-config';
import { initAgentConfig } from '@/utils/wx-init';

router.afterEach((to, from) => {
  const { title } = to.meta;
  document.title = title;

  if (from.fullPath !== '/') {
    console.log('进afterEach');
    // 初始化企业微信JSSDK
    const wxConfigParams = {
      url: window.location.href.split('#')[0]
    };
    getWxConfig(wxConfigParams).then(config => {
      initAgentConfig(config);
    });
  }
});

