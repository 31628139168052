import Vue from 'vue';
import App from './App.vue';
import router from './router';

// 重置基本样式
import '@/styles/base.css';
import 'vant/lib/index.less';
// 移动端适配
import 'amfe-flexible';
import '@/utils/ready';
import './permission';

import VueClipBoard from 'vue-clipboard2';
import VConsole from 'vconsole';

// UI框架
import {
  Tabbar,
  TabbarItem,
  Image as VanImage,
  Tag,
  Empty,
  Icon,
  Dialog,
  Toast,
  Field,
} from 'vant';
Vue.use(VueClipBoard);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(VanImage);
Vue.use(Tag);
Vue.use(Empty);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Field);

new VConsole({ theme: 'dark' });
Vue.config.productionTip = false;

await new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');
