import axios from 'axios';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 15000
});

service.interceptors.request.use(
  (config) => {
    /**
     * 注入headers
     */
    const appId = sessionStorage.getItem('sm:ready-appId'); // authCorpId
    const agentId = sessionStorage.getItem('sm:ready-agentId'); // agentId

    config.headers['authCorpId'] = appId || 'ww9036a16a400eede6';
    config.headers['agentId'] = agentId || '1000008';

    return config;
  },
  (error) => Promise.reject(error)
);

service.interceptors.response.use(
  (response) => {
    const { data: res } = response;
    const { code, data: body } = res;
    if (code !== 200) {
      const msg = res.message || res.msg || '未知错误';
      return Promise.reject(msg);
    }

    return Promise.resolve(res);
  },
  (error) => {
    const msg = error.message || '未知错误';
    return Promise.reject(error);
  }
);

export default service;
