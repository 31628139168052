import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/suggested-responses',
    component: () => import('@/views/suggested-responses'),
    meta: {
      title: '推荐回复',
      keepAlive: false
    }
  },
  {
    path: '/ai-assistant',
    component: () => import('@/views/ai-assistant'),
    meta: {
      title: 'AI助手',
      keepAlive: true
    }
  },
  {
    path: '/home',
    component: () => import('@/views/home'),
    meta: {
      title: '我的',
      keepAlive: false
    }
  },
  {
    path: '/customer-list',
    component: () => import('@/views/customer-list'),
    meta: {
      title: '客户列表',
      keepAlive: false
    }
  },
  {
    path: '*',
    redirect: '/suggested-responses'
  }
];

const router = new VueRouter({
  routes
});

export default router;
